var Extra = Extra || {}

Extra.FilterItemBlock = {
    render: (items, isAr) => {
        let renderedItems;
        if(isAr){
            renderedItems = items.map(function(item, index){
                return `
                    <div class="filter-grid-item">
                        <input type="checkbox" name="compare-select" id="compare-${index}">
                        <label class="item-block" for="compare-${index}"><img class="brand-icon" src="content/image/samsung-logo.png" alt="samsung" srcset="content/image/samsung-logo@2x.png 2x"><span class="top-label">حصرياً على موقعنا</span><i class="icon-wishlist"></i>
                        <div class="image-container"><img src="content/image/tv1.png" srcset="content/image/tv1.png 2x" alt="Miele Complete C3 Black Diamond Ecoline SGDG1"><img class="hover-image" src="content/image/tv2.png" srcset="content/image/tv2.png 2x" alt="Miele Complete C3 Black Diamond Ecoline SGDG1">
                            <p class="compare-indicator icon-tick">مقارنة</p>
                        </div>
                        <div class="item-details">
                            <div class="center-content">
                            <div class="review-block"><img src="img/review-star.png" srcset="img/review-star@2x.png 2x" alt="Review Star">
                                <p>3.5/5</p><span>9 تقييمات</span>
                            </div>
                            <div class="title">
                                <h3>سامسونج تلفزيون 49 بوصة  ذكي عالي الوضوح </h3>
                            </div>
                            <div class="addition-content">
                                <h4><img src="content/image/badge@1x.png" alt="2 year warranty" srcset="content/image/badge@2x.png 2x">كفالة لمدة سنتين</h4>
                            </div>
                            <div class="stats hide-for-mobile-only">
                                <ul>
                                <li>نوع اللوحة: ال اي دي</li>
                                <li>حجم الشاشة: ٤٩ بوصة</li>
                                <li>نمنافذ/يو اس بي : 1</li>
                                <li>حجم الشاشة: ٤٩ بوصة</li>
                                </ul>
                            </div>
                            <div class="item-features__logos">
                                <img src="content/image/4khdr.png" alt="4khdr" srcset="content/image/4khdr@2x.png 2x" class="item-features__art">
                                <img src="content/image/ultra-hd.png" alt="ultra-hd" srcset="content/image/ultra-hd@2x.png 2x" class="item-features__art">
                                <img src="content/image/hdmi-usb.png" alt="hdmi-usb" srcset="content/image/hdmi-usb@2x.png 2x" class="item-features__art">
                            </div>
                            </div>
                            <div class="right-content">
                            <div class="price-row"><span class="percent-discount">50% </span>
                                <div class="price-box">
                                <p class="price">1399 <span class="currency">رس</span></p><span class="previous-price">1900 رس</span>
                                </div>
                            </div>
                            <div class="status">
                                <p>متوفر</p>
                            </div>
                            <div class="extras">
                                <p><strong>المزيد:</strong> الوان (3)</p>
                            </div>
                            <div class="push-block">
                                <p class="red icon-gift">هدية بقيمة 250 رس</p>
                            </div>
                            </div>
                        </div>
                        <div class="item-block-footer">
                            <div class="item-block-footer-item">
                            <p class="icon-store-2">متوفر للاستلام من المعرض</p>
                            </div>
                            <div class="item-block-footer-item">
                            <p class="icon-van-outline">متوفر للتوصيل </p>
                            </div>
                        </div>
                        </label>
                    </div>
                `
            })
        }else{
             renderedItems = items.map(function (item, index) {
                return `
                    <div class="filter-grid-item">
                        <input type="checkbox" name="compare-select" id="compare-${index}">
                        <label class="item-block" for="compare-${index}"><img class="brand-icon" src="content/image/samsung-logo.png" alt="samsung" srcset="content/image/samsung-logo@2x.png 2x"><span class="top-label">Latest</span><i class="icon-wishlist"></i>
                            <div class="image-container"><img src="content/image/tv1.png" srcset="content/image/tv1.png 2x" alt="Miele Complete C3 Black Diamond Ecoline SGDG1"><img class="hover-image" src="content/image/tv2.png" srcset="content/image/tv2.png 2x" alt="Miele Complete C3 Black Diamond Ecoline SGDG1">
                                <p class="compare-indicator icon-tick">Compare</p>
                            </div>
                            <div class="item-details">
                                <div class="center-content">
                                    <div class="review-block"><img src="img/review-star.png" srcset="img/review-star@2x.png 2x" alt="Review Star">
                                        <p>3.5/5</p><span>9 Reviews</span>
                                    </div>
                                    <div class="title">
                                        <h3>Miele Complete C3 Black Diamond Ecoline SGDG1</h3>
                                    </div>
                                    <div class="addition-content">
                                        <h4><img src="content/image/badge@1x.png" alt="2 year warranty" srcset="content/image/badge@2x.png 2x">2 Year Warranty</h4>
                                    </div>
                                    <div class="stats hide-for-mobile-only">
                                        <ul>
                                            <li>Panel Type:LED</li>
                                            <li>USB Port(s): 1</li>
                                            <li>Screen Size: 32"</li>
                                            <li>3D (Y/N): No</li>
                                        </ul>
                                    </div>
                                    <div class="item-features__logos">
                                        <img src="content/image/4khdr.png" alt="4khdr" srcset="content/image/4khdr@2x.png 2x" class="item-features__art">
                                        <img src="content/image/ultra-hd.png" alt="ultra-hd" srcset="content/image/ultra-hd@2x.png 2x" class="item-features__art">
                                        <img src="content/image/hdmi-usb.png" alt="hdmi-usb" srcset="content/image/hdmi-usb@2x.png 2x" class="item-features__art">
                                    </div>
                                </div>
                                <div class="right-content">
                                    <div class="price-row"><span class="percent-discount">50% <span>Off</span></span>
                                        <div class="price-box">
                                            <p class="price">1399 <span class="currency">SR</span></p><span class="previous-price">1900 SR</span>
                                        </div>
                                    </div>
                                    <div class="status">
                                        <p>In stock</p>
                                    </div>
                                    <div class="extras">
                                        <p><strong>More:</strong> Colors (3)</p>
                                    </div>
                                    <div class="push-block">
                                        <p class="red icon-gift">1 Gift worth 250 SR</p>
                                    </div>
                                </div>
                            </div>
                            <div class="item-block-footer">
                                <div class="item-block-footer-item">
                                    <p class="icon-store-2">Available for collect in store</p>
                                </div>
                                <div class="item-block-footer-item">
                                    <p class="icon-van-outline">Available for delivery</p>
                                </div>
                            </div>
                        </label>
                    </div>
                `
            })
        }

        return renderedItems.join(' ')
    }
}
